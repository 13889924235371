import React from 'react'

import Layout from '../../../components/layout'
import Container from '../../../components/container'
import ResidenceDetails from '../../../components/residence-details'

import Real1 from '../../../images/work/01_PB_MATADOR/PHOTOS/MA_01.jpg'
import Real2 from '../../../images/work/01_PB_MATADOR/PHOTOS/MA_02.jpg'
import Real3 from '../../../images/work/01_PB_MATADOR/PHOTOS/MA_03.jpg'
import Real4 from '../../../images/work/01_PB_MATADOR/PHOTOS/MA_04.jpg'
import Real5 from '../../../images/work/01_PB_MATADOR/PHOTOS/MA_05.jpg'
import Real6 from '../../../images/work/01_PB_MATADOR/PHOTOS/MA_06.jpg'
import Real7 from '../../../images/work/01_PB_MATADOR/PHOTOS/MA_07.jpg'
import Real8 from '../../../images/work/01_PB_MATADOR/PHOTOS/MA_08.jpg'
import Real1s from '../../../images/work/01_PB_MATADOR/PHOTOS/450X280/MA-SM_01.jpg'
import Real2s from '../../../images/work/01_PB_MATADOR/PHOTOS/450X280/MA-SM_02.jpg'
import Real3s from '../../../images/work/01_PB_MATADOR/PHOTOS/450X280/MA-SM_03.jpg'
import Real4s from '../../../images/work/01_PB_MATADOR/PHOTOS/450X280/MA-SM_04.jpg'
import Real5s from '../../../images/work/01_PB_MATADOR/PHOTOS/450X280/MA-SM_05.jpg'
import Real6s from '../../../images/work/01_PB_MATADOR/PHOTOS/450X280/MA-SM_06.jpg'
import Real7s from '../../../images/work/01_PB_MATADOR/PHOTOS/450X280/MA-SM_07.jpg'
import Real8s from '../../../images/work/01_PB_MATADOR/PHOTOS/450X280/MA-SM_08.jpg'
import Process1 from '../../../images/work/01_PB_MATADOR/PROCESS/MAT_01.jpg'
import Process2 from '../../../images/work/01_PB_MATADOR/PROCESS/MAT_02.jpg'
import Process3 from '../../../images/work/01_PB_MATADOR/PROCESS/MAT_03.jpg'
import Process1s from '../../../images/work/01_PB_MATADOR/PROCESS/450X280/MAT-SM_01.jpg'
import Process2s from '../../../images/work/01_PB_MATADOR/PROCESS/450X280/MAT-SM_02.jpg'
import Process3s from '../../../images/work/01_PB_MATADOR/PROCESS/450X280/MAT-SM_03.jpg'


const Residence01 = () => (
  <Layout>
    <Container>
      <ResidenceDetails
        residenceTitle="OLD SPANISH"
        residenceLocation="PEBBLE BEACH, CALIFORNIA"
        infoList={['Remodel of existing 3,880 SF single family residence. Addition of 210 SF of floor area to a total of 4,090 SF to provide connection between existing garage and the residence. Outdoor improvements.', 'Design - 2016', 'Construction - 2017', 'Contractor: Eric\'s Building Services', 'Structural Engineer: Central Coast Engineers, Inc']}
        testimonialList={['To be able to say at the end of a building project that the final results met or exceeded my vision is a rare occurrence. This was and still is my experience while engaging with Anatoly Ostretsov for our remodel and expansion of an "Old Spanish" house here in Pebble Beach, California.', 'Anatoly\'s knowledge and personal relationships with key people while navigating through the challenging process of building here on the Monterey Peninsula was invaluable. From the first consultation to the final inspection his expertise was everything one would hope for; prompt, focused, precise, knowledgeable and calm. Plus, he brought to light many creative and clever ideas that enhanced the final design.', 'I look forward to working with Anatoly Ostretsov again on new projects in the future.', 'I give Anatoly a giant stamp of approval!', 'Ladona Minerva']}
        photos={[Real1,Real2,Real3,Real4,Real5,Real6,Real7,Real8]}
        photosSmall={[Real1s,Real2s,Real3s,Real4s,Real5s,Real6s,Real7s,Real8s]}
        processPhotos={[Process1,Process2,Process3]}
        processPhotosSmall={[Process1s,Process2s,Process3s]}
        />
    </Container>
  </Layout>
)

export default Residence01;
